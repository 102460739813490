@import "./src/assets/theme/vars.scss";
$theme-light-text: #999999;
body.theme-light {
  background-color: white !important;
  color: black !important;

  .filterSearch {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .edit-button {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .tab-divider {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  .collections-editor {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    //background-color: rgba(0, 0, 0, 0.1);
    &.active {
      //background-color: rgba(0, 0, 0, 0.2);
      background-color: rgba(150, 150, 150, 0.12);
    }
    &:hover {
      //background-color: rgba(0, 0, 0, 0.2);
      background-color: rgba(150, 150, 150, 0.12);
    }
  }

  .basecolor {
    color: black !important;
  }
  .widget-iframe .iframe-cont {
    border: 5px solid #d8d8d8 !important;
  }
  .saveme {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
  }
  .radar-axis {
    //background-color: white !important;
    background-color: rgb(228, 228, 228) !important;
  }
  .noblur {
    border: 2px solid #1a1a1a !important;
  }
  .folio-entity .myindex {
    border: 1px solid rgb(204, 204, 204) !important;
  }
  .folio-entity {
    .box-table {
      border-bottom: 1px solid rgb(204, 204, 204) !important;
    }
    &.default {
      //.titolo {
      //  border-bottom: 1px solid rgb(204, 204, 204);
      //}
      .box-table {
        border-bottom: 1px solid rgb(204, 204, 204) !important;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
  .news-annotation {
    .ck {
      color: black;
    }
    .saveme {
      border-color: rgba(0, 0, 0, 0.3);
    }
    button {
      background-color: white !important;
      color: black !important;
    }
  }
  input:-webkit-autofill {
    box-shadow: 0 0 0 30px white inset;
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
  .legend-items .legend-item:hover {
    color: black !important;
  }
  .mat-drawer,
  .stepper {
    background-color: white !important;
  }
  .chart-title:not(.noback) {
    color: white !important;
    .submenu {
      color: black !important;
    }
  }
  .chart-title.noback {
    color: black !important;
    .submenu {
      color: black !important;
    }
  }
  .network-title {
    background-color: rgba(250, 250, 250, 0.8) !important;
  }
  .menu-above,
  .menu-aside,
  .menu-bside {
    background-color: white;
    color: black;
  }
  .yukkapp,
  .loading,
  .userform {
    background-color: white;
  }
   a {
     color: $theme-light-text !important;
   }
  .treemap-options .filter,
  .treemap-filters .filter,
  .history .mat-button,
  .menu-search .inpsearch,
  .menu-search .filters,
  .menu-search .iconsearch {
    border-color: rgba(0, 0, 0, 0.2) !important;
  }
  .bullet {
    color: rgba(0, 0, 0, 0.5);
  }
  // .treemap-template{
  //   color: white !important;
  //   font-weight: bold;
  // }
  .treemap-template,
  .main-portfolio {
    color: #252424 !important;
  }
  .news-entity .news-treemap {
    color: white !important;
  }
  .icon-arrow {
    display: none;
  }
  .btnact {
    background-color: #f5f5f5 !important;
  }
  .mat-icon-button.all {
    color: black !important;
  }
  ::-webkit-scrollbar {
    background-color: #f5f5f5;
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #cccccc;
  }
  .match-btn .label {
    color: black !important;
  }
  .news-feed {
    .frame {
      color: black !important;
    }
  }
  .treemap-template,
  .news-treemap {
    .back {
      display: inherit !important;
    }
    .box-map {
      color: white !important;
    }
  }
  .trending-main {
    .box-trend {
      color: black !important;
    }
  }
  .mat-subheader {
    background-image: linear-gradient(to top, rgba(255,255,255,0.3), rgba(255, 255, 255, 1)) !important;
  }
  .mat-autocomplete-panel {
    &.distinct-panel {
      background-color: rgb(240, 240, 240) !important;
    }
  }
  .mat-autocomplete-visible {
    &.distinct-panel {
      background-color: rgb(240, 240, 240) !important;
    }
  }
  .filter-preset .mat-icon {
    color: rgb(140, 140, 140) !important;
  }
  .query-import {
    color: rgb(140, 140, 140) !important;
  }
  .letter-form {
    .form-fildo {
      &.multiple {
        border: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
  }
  .newsletter-page {
    .mat-select-arrow {
      color: rgba(0, 0, 0, 0.87);
    }
    .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
