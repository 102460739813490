$positive: #3dac00;
$neutrale: #f6d500;
$negative: #dd001a;
$warning: #ff9900;
$null: #a3a3a3;
$border1: 1px solid rgba(0, 0, 0, 0.2);
$border2: 2px solid rgba(0, 0, 0, 0.2);
$border12: 1px solid rgba(255, 255, 255, 0.2);
$greydark: #424242;
$active: rgba(0, 0, 0, 0.2);
$yukkacolor1: #1E816C;
// $yukkacolor: #94d500;
$yukkacolor: #1E816C;
$yukkacolorLight: #8ebfb5;
$yukkapp: #363636;
$black: #2c2c2c;

$test: rgba(60, 172, 0, 0.2);
